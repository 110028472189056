<template>
  <div class="content_lg">
    <div class="content_box_lg">
      <div class="title_lg">教学管理系统</div>
      <div class="left_lg"></div>
      <div class="right_lg">
        <div class="tt_lg">用户登录</div>
        <div class="int1_lg">
          <img class="img1_lg" src="../assets/img/zhanghu.png" />
          <el-input size="small"
            autofocus
            autocomplete="off"
            placeholder="请输入用户名"
            v-model.trim="username"
          ></el-input>
        </div>
        <div class="int1_lg">
          <img class="img1_lg" src="../assets/img/mima.png" />
          <el-input size="small"
            autocomplete="off"
            type="password"
            placeholder="请输入密码"
            v-model.trim="password"
          ></el-input>
        </div>
        <div class="int1_lg">
          <img class="img1_lg" src="../assets/img/yanzhengma.png" />
          <el-input size="small"
            class="int3_lg"
            placeholder="请输入验证码"
            @keyup.enter.native="login"
            v-model.trim="captcha"
          ></el-input>
          <div class="yanzhengma_lg" @click="verify">
          <img class="yanzhengma_lg_img" :src="verify1" alt />
        </div>
        </div>
        <div class="login_lg" @click="login">登录</div>
        <!-- <div @click="goregister" class="top_rt">如未注册，点此注册</div> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      username: '',
      password: '',
      captcha: '',
      verify1: '',
      token: ''
    }
  },
  mounted() {
     this.__token__()
    //  this.info()
  },
  methods: {
    //  获取用户信息
    async info() {
      const { data: res } = await this.$http.get(
        'edulogin.php/general/profile/info'
      )
      // console.log(res)
      if(JSON.stringify(res.data.info) !== '[]') {
        this.$router.push('/personal')
      }
    },
    // 获取图形验证码
    async verify() {
      this.verify1 = this.$URL + 'index.php/api/index/verify?r=' + Math.random()
     console.log(this.verify1)
    },
    // __token__令牌
    async __token__() {
      const { data: res } = await this.$http.get(
        'index.php/api/index/formtoken'
      )
      if (res.code !== 1) return
       this.verify()
      this.token = res.data.info
    },
     // 登录
    async login() {
      
      if (this.username.length <= 0) {
        return this.$message.error('请输入您的用户名')
      }
      if (this.password.length <= 0) {
        console.log(this.captcha.length)
        return this.$message.error('请输入您的密码')
      }
      if (this.captcha.length <= 0) {
        return this.$message.error('请输入您的验证码')
      }
      const { data: res } = await this.$http.post(
        'edulogin.php/index/login',
        {
          username: this.username,
          password: this.password,
          captcha: this.captcha,
          __token__: this.token
        }
      )
      console.log(111, res)
      if (res.code != 1) {
        this.token = res.data.__token__
        this.verify()
        this.captcha = ''
        return this.$message.error(res.msg)
      }
      this.$message.success(res.msg)
      window.localStorage.setItem('jxzong_activePath','/welcome')
      this.$router.push('/welcome')
    },
    // 注册
    goregister() {
      this.$router.push('/register')
    }
  },
}
</script>
<style scoped>
@import '../assets/css/login.css';
</style>
